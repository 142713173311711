exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-donations-donations-js": () => import("./../../../src/pages/donations/donations.js" /* webpackChunkName: "component---src-pages-donations-donations-js" */),
  "component---src-pages-donations-impact-js": () => import("./../../../src/pages/donations/impact.js" /* webpackChunkName: "component---src-pages-donations-impact-js" */),
  "component---src-pages-donations-more-js": () => import("./../../../src/pages/donations/more.js" /* webpackChunkName: "component---src-pages-donations-more-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-foundation-about-us-js": () => import("./../../../src/pages/foundation/about-us.js" /* webpackChunkName: "component---src-pages-foundation-about-us-js" */),
  "component---src-pages-foundation-team-js": () => import("./../../../src/pages/foundation/team.js" /* webpackChunkName: "component---src-pages-foundation-team-js" */),
  "component---src-pages-foundation-volunteers-js": () => import("./../../../src/pages/foundation/volunteers.js" /* webpackChunkName: "component---src-pages-foundation-volunteers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

